.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030; 
  background-color: white; 
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 60px;
  line-height: 40px;
}

.card-footer {
  background-color: #f8f9fa; /* Light grey background */
  padding: 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners for the box */
}

.card-footer large {
  display: block;
  font-size: 18px;
  color: #343a40; /* Darker text color */
}

.floating-menu-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  z-index: 1000;
}

.menu-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
}

.floating-menu-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0; 
}

.floating-menu-bar.show {
  max-height: 100px;
}

.menu-option {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.menu-option:hover {
  border: 2px solid black;
}

.btn-primary.floating-menu {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary.floating-menu:hover {
  border: 2px solid black;
}

.ms-2{
  margin-left: 2rem;
}

.card-title {
  font-size: medium;
  font-weight: 500;
}

.card-text {
  font-size: smaller;
  font-weight: 300;
}

.card-title1 {
  font-size: medium;
  font-weight: 500;
}

.card-text1 {
  font-size: smaller;
  font-weight: 300;
}

p {
  font-size: smaller;
  font-weight: 300;
}

h4 {
  font-size: medium;
  font-weight: 700;
}

li {
  font-size: smaller;
  font-weight: 300;
}

.whatsapp-icon {
  position: fixed;
  bottom: 225px;
  right: 15px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color:white;
  transition: transform 0.3s ease, background-color 0.3s ease;
}


.whatsapp-icon:hover {
  transform: scale(1.2); 
  background-color: #218838; 
}

.phone-icon {
  position: fixed;
  bottom: 150px;
  right: 15px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color:white;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.phone-icon:hover {
  transform: scale(1.2); 
  background-color: #218838; 
}
